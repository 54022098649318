/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

/*
* This file follows the examples outlined in
* https://www.w3.org/TR/wai-aria-practices/examples/disclosure/disclosure-navigation.html
* for the disclosure design pattern. Creating a new construct of this will give you
* a button that will expand and reveal content with proper controls.
*/

var AccessibleDisclosureButton = function(domNode) {
  this.domNode = domNode;
};
window.AccessibleDisclosureButton = AccessibleDisclosureButton;

AccessibleDisclosureButton.prototype.init = function() {
  this.controlledNode = false;
  var id = this.domNode.getAttribute('aria-controls');
  var ariaExpanded = this.domNode.getAttribute('aria-expanded') === 'true';
  if (id) {
    this.controlledNode = document.getElementById(id);
  }
  if (!ariaExpanded) {
    this.hideContent();
  }

  this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
  this.domNode.addEventListener('click', this.handleClick.bind(this));
  this.domNode.addEventListener('focus', this.handleFocus.bind(this));
  this.domNode.addEventListener('blur', this.handleBlur.bind(this));
};

AccessibleDisclosureButton.prototype.showContent = function() {
  if (this.controlledNode) {
    this.controlledNode.classList.remove('display-none');
    this.controlledNode.classList.add('display-block');
  }
};

AccessibleDisclosureButton.prototype.hideContent = function() {
  if (this.controlledNode) {
    this.controlledNode.classList.add('display-none');
    this.controlledNode.classList.remove('display-block');
  }
};

AccessibleDisclosureButton.prototype.toggleExpand = function() {
  if (this.domNode.getAttribute('aria-expanded') === 'true') {
    this.domNode.setAttribute('aria-expanded', 'false');
    this.hideContent();
  } else {
    this.domNode.setAttribute('aria-expanded', 'true');
    this.showContent();
  }
};

/* EVENT HANDLERS */

AccessibleDisclosureButton.prototype.handleKeydown = function(event) {
  switch (event.key) {
    case 'Enter':
      this.toggleExpand();
      event.stopPropagation();
      event.preventDefault();
      break;
    default:
      break;
  }
};

AccessibleDisclosureButton.prototype.handleClick = function(event) {
  event.preventDefault();
  this.toggleExpand();
};

AccessibleDisclosureButton.prototype.handleFocus = function(event) {
  event.preventDefault();
  this.domNode.classList.add('focus-state');
};

AccessibleDisclosureButton.prototype.handleBlur = function(event) {
  event.preventDefault();
  this.domNode.classList.remove('focus-state');
};
